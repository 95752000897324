import React, {
  FC, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Field, Formik, useFormik, FormikValues, FormikProps,
} from 'formik';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik/dist/types';
import { Tooltip } from '@mui/material';
import { basicSchema } from '../validationSchemas/carouselForm';
import { FormElement, FormTitle, TextInput } from './CustomerForm.styles';
import { SaveButton, ButtonDelete, ButtonItem } from '../globalStyles';
import {
  LabelItem,
  Wrapper,
  ButtonGroup,
  GroupVertical, RadioRow, RadioRowLabel, RadioGroupComponent,
} from './CarouselForm.styles';
import CustomSelect from '../customComponents/CustomSelect';
import InputComponent from '../customComponents/CustomInput';
import CustomFileInput from '../customComponents/CustomFileInput/CustomFileInput';
import { SelectedFileName, InputWrapper } from '../customComponents/CustomFileInput/index.styles';
import CheckboxComponent from '../customComponents/CustomCheckbox/CustomCheckbox';
import RadioComponent from '../customComponents/CustomRadio/CustomRadio';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getCustomers, setCustomer } from '../store/dataSlice';
import CarouselService from '../services/CarouselService';
import { copyTextToClipboard } from '../utils';
import { IFeedProvider } from '../models/IFeedProvider';
import { providers } from '../constants';

interface CarouselForm {
    setCustomerForm: (value: boolean) => void
    setCustomerPreview: (value: boolean) => void
    setEditCustomer: (value: boolean) => void
}

const CarouselForm = ({ setCustomerForm, setCustomerPreview, setEditCustomer }: CarouselForm) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const {
    customers, templates, feedProviders, isLoading, selectedCustomer,
  } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<{name: string, size: string, type: string}>();
  const [fileBg, setFileBg] = useState<{name: string, size: string, type: string}>();

  const resetFile = () => {
    setFile({
      name: '',
      size: '',
      type: '',
    });
  };

  const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
    const provider = feedProviders.find((el) => el.id === values.provider);
    const carousel = customers.find((el) => el.id === values.customerId)?.carousel;

    const form = new FormData();
    carousel?.id && form.append('carousel', carousel?.id);
    form.append('firstSlideLogo', values.photoFile);
    form.append('backgroundImage', values.photoFileBg);
    form.append('template', values.template);
    form.append('customer', values.customerId);
    form.append('feedProvider', values.provider);
    form.append('feedCredentials', JSON.stringify({
      ...(provider?.feedLogin) && { feedLogin: values.feedLogin },
      ...(provider?.feedPassword) && { feedPassword: values.feedPassword },
      ...(provider?.feedSecretKey) && { feedSecretKey: values.feedSecretKey },
    }));
    form.append('firstSlideText', values.firstSlideText);
    form.append('isAddressFieldKey', values.isAddressFieldKey);
    form.append('isPriceFieldKey', values.isPriceFieldKey);
    form.append('isBedroomsFieldKey', values.isBedroomsFieldKey);
    form.append('isBathroomsFieldKey', values.isBathroomsFieldKey);
    form.append('isLivingRoomsKey', values.isLivingRoomsKey);
    form.append('isAddressField', values.isAddressField);
    form.append('isPriceField', values.isPriceField);
    form.append('isBedroomsField', values.isBedroomsField);
    form.append('isBathroomsField', values.isBathroomsField);
    form.append('isLivingRooms', values.isLivingRooms);
    form.append('hours', values.hours);

    try {
      if (carousel) {
        await CarouselService.update(form);
        toast.success('Successfully updated', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await CarouselService.create(form);
        toast.success('Successfully created', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      resetFile();
      dispatch(getCustomers());
    } catch (e) {
      console.log(e);
    }
  };

  const onReset = (values: any, helpers: FormikHelpers<any>) => {
    helpers.resetForm({
      values: {
        firstSlideText: '123213',
      },
    });
    console.log('qwe');
  };

  const onSelectCustomer = (val: string, setFieldValue: (field: string, value: any) => void) => {
    console.log('SELECT CUSTOMER');
    const customer = customers.find((el) => val === el.id);
    setCustomerPreview(true);
    setCustomerForm(false);
    setEditCustomer(false);
    if (customer) {
      dispatch(setCustomer(customer));
    }
    if (customer?.carousel) {
      setFieldValue('template', customer.carousel.template.id);
      setFieldValue('provider', customer.carousel.feedProvider.id);
      setFieldValue('firstSlideText', customer.carousel.firstSlideText);
      customer.carousel.feedProvider.feedLogin && setFieldValue('feedLogin', customer.carousel?.feedCredentials?.feedLogin);
      customer.carousel.feedProvider.feedPassword && setFieldValue('feedPassword', customer.carousel?.feedCredentials?.feedPassword);
      customer.carousel.feedProvider.feedSecretKey && setFieldValue('feedSecretKey', customer.carousel?.feedCredentials?.feedSecretKey);
      setFieldValue('isAddressFieldKey', customer.carousel.isAddressFieldKey);
      setFieldValue('isPriceFieldKey', customer.carousel.isPriceFieldKey);
      setFieldValue('isBedroomsFieldKey', customer.carousel.isBedroomsFieldKey);
      setFieldValue('isBathroomsFieldKey', customer.carousel.isBathroomsFieldKey);
      setFieldValue('isLivingRoomsKey', customer.carousel.isLivingRoomsKey);
      setFieldValue('isAddressField', customer.carousel.isAddressField);
      setFieldValue('isPriceField', customer.carousel.isPriceField);
      setFieldValue('isBedroomsField', customer.carousel.isBedroomsField);
      setFieldValue('isBathroomsField', customer.carousel.isBathroomsField);
      setFieldValue('isLivingRooms', customer.carousel.isLivingRooms);
      setFieldValue('hours', customer.carousel.hours);
    } else {
      setFieldValue('template', '');
      setFieldValue('feedUrl', '');
      setFieldValue('provider', '');
      setFieldValue('firstSlideText', '');
      setFieldValue('feedLogin', '');
      setFieldValue('feedPassword', '');
      setFieldValue('feedSecretKey', '');
      setFieldValue('photoFile', '');
      setFieldValue('photoFileBg', '');
    }
  };

  const handleDeleteCarousel = async (customerId: string) => {
    const customer = customers.find((el) => el.id === customerId);
    if (customer?.carousel) {
      await CarouselService.delete(customer.carousel.id);
      toast.success('Successfully deleted carousel', {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(getCustomers());
    } else {
      toast.warning('There is no related carousel', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getProvider = () => {
    const provider = feedProviders.find((el) => el.id === formRef.current?.values.provider);
    return provider ? provider.feedName : 'Jupix';
  };

  return (
    <Wrapper>
      <Formik
        innerRef={formRef}
        initialValues={{
          customerId: selectedCustomer.id || '',
          template: '',
          provider: '',
          firstSlideText: '',
          feedLogin: '',
          feedUrl: '',
          feedPassword: '',
          feedSecretKey: '',
          photoFile: '',
          photoFileBg: '',
          isAddressFieldKey: '',
          isPriceFieldKey: '',
          isBedroomsFieldKey: '',
          isBathroomsFieldKey: '',
          isLivingRoomsKey: '',
          isAddressField: false,
          isPriceField: false,
          isBedroomsField: false,
          isBathroomsField: false,
          isLivingRooms: false,
          hours: '12hours',
        }}
        validationSchema={basicSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting, setFieldValue, setFieldTouched, setTouched, touched, errors, values, setErrors,
        }) => (
          <FormElement>
            <FormTitle>Change Carousel</FormTitle>
            <ButtonGroup>
              <CustomSelect
                fullWidth
                name="customerId"
                label="Customer ID"
                selectCallback={
                  (val) => {
                    onSelectCustomer(val, setFieldValue);
                    setErrors({});
                  }
                }
                showId
                values={customers.map((el) => ({
                  name: el.customerDisplayName || el.name || el.middleName || el.title,
                  id: el.id,
                }))}
              />
              <ButtonItem onClick={() => {
                setCustomerForm(true);
                setEditCustomer(false);
                setCustomerPreview(false);
              }}
              >
                CRETE NEW
              </ButtonItem>
            </ButtonGroup>

            <CustomSelect
              name="template"
              label="Choose Template"
              values={templates.map((el) => ({
                name: el.name,
                id: el.id,
              }))}
            />

            <CustomSelect
              name="provider"
              label="Feed Provider"
              values={feedProviders.map((el) => ({
                name: el.feedName,
                id: el.id,
              }))}
            />

            {
              (() => {
                const provider = feedProviders.find((e) => e.id === formRef.current?.values.provider) || {} as IFeedProvider;
                return (
                  <>
                    {provider.feedLogin
                        && <InputComponent name="feedLogin" type="text" label="Feed Login" />}
                    {provider.feedPassword
                        && <InputComponent name="feedPassword" type="text" label="Feed Password" />}
                    {provider.feedSecretKey
                        && <InputComponent name="feedSecretKey" type="text" label="Feed Secret Key" />}
                  </>
                );
              })()
            }

            <InputComponent disabled value={feedProviders.find((e) => e.id === values.provider)?.feedSrc || ''} name="feedUrl" type="text" label="Feed URL" />

            <InputComponent name="firstSlideText" type="text" label="First Slide Text" />

            <Tooltip title="Recommended resolution 300x300" placement="right">
              <GroupVertical style={{ width: 'fit-content' }}>
                <LabelItem>Upload Logo</LabelItem>
                <InputWrapper>
                  <CustomFileInput
                    type="file"
                    label="photoFile"
                    name="photoFile"
                    onBlur={() => { setFieldTouched('photoFile', true); }}
                    errors={errors}
                    touched={touched}
                    setFile={(imgData) => {
                      setFile(imgData);
                      setFieldValue('photoFile', imgData);
                    }}
                  />
                  {file?.name && <SelectedFileName>{file.name}</SelectedFileName>}
                </InputWrapper>
                {/* <pre>{JSON.stringify(errors)}</pre> */}
                {/* <pre style={{ width: '200px' }}>{JSON.stringify(values)}</pre> */}
                {/* <pre>{JSON.stringify(touched)}</pre> */}
              </GroupVertical>
            </Tooltip>

            <Tooltip title="Recommended resolution 1080x1080" placement="right">
              <GroupVertical style={{ width: 'fit-content' }}>
                <LabelItem>Background Image</LabelItem>
                <InputWrapper>
                  <CustomFileInput
                    type="file"
                    label="photoFileBg"
                    name="photoFileBg"
                    errors={errors}
                    touched={touched}
                    setFile={(imgData) => {
                      setFileBg(imgData);
                      setFieldValue('photoFileBg', imgData);
                    }}
                  />
                  {fileBg?.name && <SelectedFileName>{fileBg.name}</SelectedFileName>}
                </InputWrapper>
              </GroupVertical>
            </Tooltip>

            <ButtonGroup>
              <CustomSelect
                style={{
                  width: '100%',
                }}
                fullWidth
                name="isAddressFieldKey"
                label="Address Field"
                // @ts-ignore
                values={providers[getProvider()].address.map((el) => ({
                  name: el.name,
                  id: el.key,
                }))}
              />
              <CheckboxComponent name="isAddressField" type="checkbox" label="Show" />
            </ButtonGroup>

            <ButtonGroup>
              <CustomSelect
                style={{
                  width: '100%',
                }}
                fullWidth
                name="isPriceFieldKey"
                label="Price"
                  // @ts-ignore
                values={providers[getProvider()].price.map((el) => ({
                  name: el.name,
                  id: el.key,
                }))}
              />
              <CheckboxComponent name="isPriceField" type="checkbox" label="Show" />
            </ButtonGroup>

            <ButtonGroup>
              <CustomSelect
                style={{
                  width: '100%',
                }}
                fullWidth
                name="isBedroomsFieldKey"
                label="Bedrooms"
                  // @ts-ignore
                values={providers[getProvider()].bedrooms.map((el) => ({
                  name: el.name,
                  id: el.key,
                }))}
              />
              <CheckboxComponent name="isBedroomsField" type="checkbox" label="Show" />
            </ButtonGroup>

            <ButtonGroup>
              <CustomSelect
                style={{
                  width: '100%',
                }}
                fullWidth
                name="isBathroomsFieldKey"
                label="Bathrooms"
                  // @ts-ignore
                values={providers[getProvider()].bathrooms.map((el) => ({
                  name: el.name,
                  id: el.key,
                }))}
              />
              <CheckboxComponent name="isBathroomsField" type="checkbox" label="Show" />
            </ButtonGroup>

            <ButtonGroup>
              <CustomSelect
                style={{
                  width: '100%',
                }}
                fullWidth
                name="isLivingRoomsKey"
                label="Living Rooms"
                  // @ts-ignore
                values={providers[getProvider()].livingRooms.map((el) => ({
                  name: el.name,
                  id: el.key,
                }))}
              />
              <CheckboxComponent name="isLivingRooms" type="checkbox" label="Show" />
            </ButtonGroup>

            <RadioGroupComponent
              defaultValue="12hours"
              name="radioButtonsGroup"
            >
              <RadioRowLabel>Autoupdate time</RadioRowLabel>
              <RadioRow>
                <RadioComponent value="12hours" name="hours" type="radio" label="Default 12 Hours" />
                <RadioComponent value="8hours" name="hours" type="radio" label="8 Hours" />
                <RadioComponent value="4hours" name="hours" type="radio" label="4 Hours" />
              </RadioRow>
            </RadioGroupComponent>

            <GroupVertical>
              <InputComponent
                name="carouselUrl"
                disabled
                value={values.customerId && `https://carousel.digital-media.co.uk/customer?id=${values.customerId}`}
                type="text"
                label="Carousel URL"
              />

              <ButtonItem
                onClick={() => copyTextToClipboard(values.customerId && `https://carousel.digital-media.co.uk/customer?id=${values.customerId}`)}
              >
                COPY
              </ButtonItem>
            </GroupVertical>

            <ButtonGroup>
              <SaveButton type="submit" variant="text">{customers.find((el) => el.id === values.customerId)?.carousel ? 'SAVE' : 'CREATE'}</SaveButton>
              <ButtonDelete onClick={() => handleDeleteCarousel(values.customerId)} variant="text">DELETE</ButtonDelete>
            </ButtonGroup>
          </FormElement>
        )}
      </Formik>
    </Wrapper>
  );
};

export default CarouselForm;
