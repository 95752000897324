import { AxiosResponse } from 'axios';
import $api from '../http';
import { CarouselOptionsResponse } from '../models/response/CarouselOptions';

export default class CarouselService {
  static fetchOptions(): Promise<AxiosResponse<CarouselOptionsResponse>> {
    return $api.get<CarouselOptionsResponse>('/carousel/options');
  }

  static create(form: any): Promise<AxiosResponse<CarouselOptionsResponse>> {
    return $api.post<CarouselOptionsResponse>('/carousel/create', form);
  }

  static update(form: any): Promise<AxiosResponse> {
    return $api.put('/carousel/update', form);
  }

  static delete(id: string): Promise<AxiosResponse> {
    return $api.post('/carousel/delete', { id });
  }
}
