import React, {
  FC, useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import {
  Form, Formik, FormikProps, FormikValues,
} from 'formik';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import {
  FormElement, FormSubtitle, FormTitle, TextInput, Wrapper,
} from './CustomerForm.styles';
import { SaveButton } from '../globalStyles';
import InputComponent from '../customComponents/CustomInput';
import CheckboxComponent from '../customComponents/CustomCheckbox/CustomCheckbox';
import basicSchema from '../validationSchemas/customerForm';
import { ButtonGroup, FormRow, FormCol } from './CarouselForm.styles';
import CustomerService from '../services/CustomerService';
import { getCustomers, setCustomer } from '../store/dataSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ICustomer } from '../models/ICustomer';
import customerPreview from './CustomerPreview';
import { InputsRow } from './CustomerPreview.style';

interface CustomerFormI {
    setCustomerForm: (value: boolean) => void
    setCustomerPreview: (value: boolean) => void
    isPreview: boolean
    isEditCustomer: boolean
}

const CustomerForm: React.FC<CustomerFormI> = ({
  setCustomerForm, isPreview, isEditCustomer, setCustomerPreview,
}) => {
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { selectedCustomer } = useAppSelector((state) => state.data);

  useEffect(() => {
    if (formRef.current && !isEditCustomer) {
      formRef.current.setFieldValue('title', '');
      formRef.current.setFieldValue('name', '');
      formRef.current.setFieldValue('middleName', '');
      formRef.current.setFieldValue('lastName', '');
      formRef.current.setFieldValue('suffix', '');
      formRef.current.setFieldValue('companyName', '');
      formRef.current.setFieldValue('customerDisplayName', '');
      formRef.current.setFieldValue('email', '');
      formRef.current.setFieldValue('mobilePhoneNumber', '');
      formRef.current.setFieldValue('phoneNumber', '');
      formRef.current.setFieldValue('fax', '');
      formRef.current.setFieldValue('other', '');
      formRef.current.setFieldValue('website', '');
      formRef.current.setFieldValue('isSubCustomer', false);
      formRef.current.setFieldValue('streetAddress', '');
      formRef.current.setFieldValue('city', '');
      formRef.current.setFieldValue('county', '');
      formRef.current.setFieldValue('country', '');
    }
  }, [isEditCustomer]);

  const onSubmit = async (values: any, actions: any) => {
    const form = {
      ...(isEditCustomer && { id: selectedCustomer?.id }),
      title: values.title,
      name: values.name,
      middleName: values.middleName,
      lastName: values.lastName,
      suffix: values.suffix,
      companyName: values.companyName,
      customerDisplayName: values.customerDisplayName,
      email: values.email,
      mobilePhoneNumber: values.mobilePhoneNumber,
      phoneNumber: values.phoneNumber,
      fax: values.fax,
      other: values.other,
      website: values.website,
      isSubCustomer: values.isSubCustomer,
      billing: {
        streetAddress: values.streetAddress,
        city: values.city,
        county: values.county,
        postcode: values.postcode,
        country: values.country,
      },
    };
    try {
      if (isEditCustomer) {
        const customer = await CustomerService.updateCustomer(form);
        dispatch(setCustomer(customer.data.customer));
        toast.success('Successfully updated', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const customer = await CustomerService.createCustomer(form);
        dispatch(setCustomer(customer.data.customer));
        toast.success('Successfully created', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(getCustomers());
    }
  };

  return (
    <Wrapper>
      <Formik
        innerRef={formRef}
        initialValues={isEditCustomer ? {
          title: selectedCustomer.title,
          name: selectedCustomer.name,
          middleName: selectedCustomer.middleName,
          lastName: selectedCustomer.lastName,
          suffix: selectedCustomer.suffix,
          companyName: selectedCustomer.companyName,
          customerDisplayName: selectedCustomer.customerDisplayName,
          email: selectedCustomer.email,
          mobilePhoneNumber: selectedCustomer.mobilePhoneNumber,
          phoneNumber: selectedCustomer.phoneNumber,
          fax: selectedCustomer.fax,
          other: selectedCustomer.other,
          website: selectedCustomer.website,
          isSubCustomer: selectedCustomer.isSubCustomer,
          streetAddress: selectedCustomer.billing.streetAddress,
          postcode: selectedCustomer.billing.postcode,
          city: selectedCustomer.billing.city,
          county: selectedCustomer.billing.county,
          country: selectedCustomer.billing.country,
        } : {
          title: '',
          name: '',
          middleName: '',
          lastName: '',
          suffix: '',
          companyName: '',
          customerDisplayName: '',
          email: '',
          mobilePhoneNumber: '',
          phoneNumber: '',
          fax: '',
          other: '',
          website: '',
          isSubCustomer: false,
          streetAddress: '',
          postcode: '',
          city: '',
          county: '',
          country: '',
        }}
        validationSchema={basicSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <FormElement>

            <FormTitle>{isEditCustomer ? 'Edit Customer' : 'Add new customer' }</FormTitle>

            <FormSubtitle>Name and contact</FormSubtitle>

            <InputsRow>
              <InputComponent
                style={{
                  flexBasis: '65px',
                }}
                name="title"
                type="text"
                label=" Title"
              />
              <InputComponent
                style={{
                  flexBasis: '65px',
                  flexGrow: 3,
                }}
                name="name"
                type="text"
                label="Name"
              />
              <InputComponent
                style={{
                  flexBasis: '65px',
                  flexGrow: 2,
                }}
                name="middleName"
                type="text"
                label="Middle Name"
              />
              <InputComponent
                style={{
                  flexBasis: '65px',
                  flexGrow: 3,
                }}
                name="lastName"
                type="text"
                label="Last Name"
              />
              <InputComponent
                style={{
                  flexBasis: '65px',
                }}
                name="suffix"
                type="text"
                label="Suffix"
              />
            </InputsRow>

            <FormRow>
              <FormCol>
                <InputComponent name="companyName" type="text" label="Company Name" />
                <InputComponent name="email" type="text" label="Email" />
                <InputComponent name="mobilePhoneNumber" type="text" label="Mobile Phone" />
                <InputComponent name="other" type="text" label="Other" />
              </FormCol>
              <FormCol>
                <InputComponent name="customerDisplayName" type="text" label="Customer Display Name" />
                <InputComponent name="phoneNumber" type="text" label="Phone Number" />
                <InputComponent name="fax" type="text" label="Fax" />
                <InputComponent name="website" type="text" label="Website" />
              </FormCol>
            </FormRow>

            <CheckboxComponent name="isSubCustomer" type="checkbox" label="Is a sub-customer" />

            <FormSubtitle>Billing address</FormSubtitle>

            <InputComponent multiline rows={3} name="streetAddress" type="text" label="Street Address" />

            <FormRow>
              <FormCol>
                <InputComponent name="city" type="text" label="City" />
                <InputComponent name="postcode" type="text" label="Postcode" />
              </FormCol>
              <FormCol>
                <InputComponent name="county" type="text" label="County" />
                <InputComponent name="country" type="text" label="Country" />
              </FormCol>
            </FormRow>

            <ButtonGroup>
              <SaveButton disabled={isSubmitting} type="submit" variant="text">SAVE</SaveButton>
              <SaveButton
                disabled={isSubmitting}
                onClick={() => {
                  setCustomerForm(false);
                  setCustomerPreview(!_.isEmpty(selectedCustomer));
                }}
                variant="text"
              >
                CANCEL
              </SaveButton>
            </ButtonGroup>
          </FormElement>
        )}
      </Formik>
    </Wrapper>
  );
};

export default CustomerForm;
