import { useField } from 'formik';
import { MenuItem } from '@mui/material';
import React from 'react';
import { SelectProps } from '@mui/material/Select/Select';
import { log } from 'util';
import { ErrorElement } from '../components/CustomerForm.styles';
import {
  InputWrapper, LabelItem, MenuItemElement, SelectItem,
} from '../components/CarouselForm.styles';

interface Select {
    label: string,
    name: string,
    values: Array<{id: string, name: string}>,
    showId?: boolean,
    initial?: string,
    selectCallback?: (val: string) => void,
}

const CustomSelect = ({
  label, values, showId = false, initial, selectCallback = () => {}, style, ...props
}: Select & SelectProps) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  return (
    <InputWrapper style={style}>
      <LabelItem>{label}</LabelItem>
      <SelectItem
        {...field}
        {...props}
        onChange={(e) => {
          selectCallback(e.target.value as string);
          field.onChange(e);
        }}
        sx={{ boxShadow: 'none' }}
        className={meta.touched && meta.error ? 'input-error' : ''}
      >
        {/* <MenuItemElement value="">{initial}</MenuItemElement> */}
        {
                  values.map((el) => <MenuItemElement key={el.id} value={el.id}>{showId ? (`${el.name} ${el.id.substring(0, 10)}...`) : el.name }</MenuItemElement>)
                }
      </SelectItem>
      {meta.touched && meta.error && <ErrorElement className="error">{meta.error}</ErrorElement>}
    </InputWrapper>
  );
};
export default CustomSelect;
