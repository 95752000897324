import React, { FC, useEffect, useState } from 'react';
import { AdminPageFormWrapper } from './AdminPageForm.style';
import CarouselForm from './CarouselForm';
import CustomerForm from './CustomerForm';
import CustomerPreview from './CustomerPreview';

const MainPage: FC = () => {
  const [customerForm, setCustomerForm] = useState(false);
  const [customerPreview, setCustomerPreview] = useState(false);
  const [isEditCustomer, setEditCustomer] = useState(false);

  return (
    <AdminPageFormWrapper>
      <CarouselForm
        setCustomerForm={setCustomerForm}
        setCustomerPreview={setCustomerPreview}
        setEditCustomer={setEditCustomer}
      />
      {customerForm && (
      <CustomerForm
        isPreview={customerPreview}
        isEditCustomer={isEditCustomer}
        setCustomerPreview={setCustomerPreview}
        setCustomerForm={setCustomerForm}
      />
      )}
      {customerPreview
                && (
                <CustomerPreview
                  setCustomerForm={setCustomerForm}
                  setCustomerPreview={setCustomerPreview}
                  setEditCustomer={setEditCustomer}
                />
                )}
    </AdminPageFormWrapper>
  );
};

export default MainPage;
