import React, { FC, useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoginFormWrapper } from './LoginForm.style';
import { useAppDispatch } from '../hooks';
import { login } from '../store/dataSlice';

const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch = useAppDispatch();
  return (
    <LoginFormWrapper>
      <TextField
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        type="text"
        placeholder="Login"
      />
      <TextField
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        placeholder="Password"
      />
      <Button variant="outlined" onClick={() => dispatch(login({ email, password }))}>
        Login
      </Button>
    </LoginFormWrapper>
  );
};

export default LoginForm;
