import styled from '@emotion/styled';

export const FileInputElement = styled('input')`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const FileInputWrapper = styled('label')`
  position: relative;
  cursor: pointer;
`;

export const InputWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
`;

export const SelectedFileName = styled('span')`
  font-weight: 300;
  font-size: 12px;
`;
