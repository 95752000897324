import styled from '@emotion/styled';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

export const CheckboxComponent = styled(Checkbox)`
`;

export const CheckboxWrapper = styled('label')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  cursor: pointer;
  .MuiTypography-root {
    color: #8A8A8A;
    font-size: 13px;
    font-weight: 300;
  }
`;

export const CheckboxTitle = styled('p')`
  position: relative;
  color: #8A8A8A;
  font-size: 18px;
  font-weight: 300;
  transform: translate(0, -1.5px) scale(0.75);
  text-transform: capitalize;
  left: -10px;
`;
