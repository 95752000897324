import {
  createSlice, PayloadAction, createAsyncThunk, AnyAction,
} from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { IUser } from '../models/IUser';
import { ITemplate } from '../models/ITemplate';
import { IFeedProvider } from '../models/IFeedProvider';
import { ICustomer } from '../models/ICustomer';
import { CarouselOptionsResponse } from '../models/response/CarouselOptions';
import CarouselService from '../services/CarouselService';
import CustomerService from '../services/CustomerService';
import { CustomerResponse } from '../models/response/CustomerResponse';
import AuthService from '../services/AuthService';
import { AuthResponse } from '../models/response/AuthResponse';
import { API_URL } from '../http';

export const getCustomers = createAsyncThunk<CustomerResponse, undefined, {rejectValue: string}>(
  'data/getCustomers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getAllCustomers();
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getOptions = createAsyncThunk<CarouselOptionsResponse, undefined, {rejectValue: string}>(
  'data/getOptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CarouselService.fetchOptions();
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const registration = createAsyncThunk<AuthResponse, {email: string, password: string}, {rejectValue: string}>(
  'data/registration',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await AuthService.registration(email, password);
      localStorage.setItem('token', response.data.accessToken);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const login = createAsyncThunk<AuthResponse, {email: string, password: string}, {rejectValue: string}>(
  'data/login',
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const response = await AuthService.login(email, password);
      localStorage.setItem('token', response.data.accessToken);
      await dispatch(getCustomers());
      await dispatch(getOptions());
      toast.success('Successfully logged in', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const logout = createAsyncThunk<undefined, undefined, {rejectValue: string}>(
  'data/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.logout();
      localStorage.removeItem('token');
      toast.success('Successfully logged out', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const checkAuth = createAsyncThunk<AuthResponse, undefined, {rejectValue: string}>(
  'data/checkAuth',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, { withCredentials: true });
      console.log(response);
      setTimeout(() => {
        localStorage.setItem('token', response.data.accessToken);
      });
      await dispatch(getCustomers());
      await dispatch(getOptions());
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

type DataState = {
    user: IUser;
    isLoading: boolean;
    isAuth: boolean;
    templates: Array<ITemplate>;
    feedProviders: Array<IFeedProvider>;
    customers: Array<ICustomer>;
    selectedCustomer: ICustomer;
};

const initialState: DataState = {
  user: {} as IUser,
  isAuth: false,
  isLoading: false,
  templates: [],
  feedProviders: [],
  customers: [],
  selectedCustomer: {} as ICustomer,
};

const todoSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setCustomer(state, action: PayloadAction<ICustomer>) {
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = false;
        state.user = {} as IUser;
      })
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      })
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCustomers.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        // state.isLoading = false
        state.customers = action.payload.customers;
      })
      .addCase(getOptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.templates = action.payload.templates;
        state.feedProviders = action.payload.feedProviders;
      });
  },
});

export const { setCustomer } = todoSlice.actions;

export default todoSlice.reducer;
