import styled from '@emotion/styled';
import { css } from '@emotion/react';
import TextField from '@mui/material/TextField';
import { Form } from 'formik';
import { GroupVertical } from './CarouselForm.styles';

export const Wrapper = styled('div')` 
  padding-left: 35px;
  border-left: 1px solid #999999;
`;

const TitleBase = css`
  color: #000000;
  font-size: 22px;
  font-weight: 300;
  padding: 0;
  margin: 0;`;

export const FormTitle = styled('h2')`
  ${TitleBase}
`;

export const FormSubtitle = styled('h3')`
  ${TitleBase};
  font-size: 16px;
`;
export const TextInput = styled(TextField)`
  .MuiInput-root {
    font-weight: 400 !important;
    color: #646464;
    font-size: 17px;
    margin-top: 0 !important;
  }
  .MuiInput-input {
    color: #646464;
  }
  .Mui-focused {
    color: #8A8A8A;
  }
  .MuiFormLabel-root {
    top: -20px !important;
    color: #8A8A8A !important;
    font-size: 18px !important;
    font-weight: 300;
  }
  padding: 10px 10px;
  border-radius: 0px;
  border: 1px solid #DBDFE4;
  outline: none !important;
  color: #646464;
`;

export const FormElement = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;
export const ErrorElement = styled('div')`
  position: absolute;
  bottom: -17px;
  color: #f83434;
  font-size: 12px;
  font-weight: 400;
`;

export const InputGroup = styled(GroupVertical)`
  position: relative;
  gap: 10px;
`;
