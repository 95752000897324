import { useField } from 'formik';
import React from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import {
  ErrorElement, FormTitle, InputGroup, TextInput,
} from '../components/CustomerForm.styles';

interface Input {
    label: string,
    name?: string,
    type: string,
    value?: string
}

const CustomInput = ({ label, style, ...props }: Input & TextFieldProps) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <InputGroup style={style}>
      <TextInput
        {...field}
        {...props}
        className={meta.touched && meta.error ? 'input-error' : ''}
        focused
        label={label}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{ shrink: true }}
      />
      {meta.touched && meta.error && <ErrorElement className="error">{meta.error}</ErrorElement>}
    </InputGroup>
  );
};
export default CustomInput;
