export const providers = {
  Smelogin: {
    address: [
      {
        key: 'address.address_2',
        name: 'address_2',
      },
      {
        key: 'address.display_address',
        name: 'display_address',
      },
      {
        key: 'address.town',
        name: 'town',
      },
    ],
    price: [
      {
        key: 'price_information.price',
        name: 'price',
      },
    ],
    bedrooms: [
      {
        key: 'details.bedrooms',
        name: 'bedrooms',
      },
      {
        key: 'details.bedrooms_box',
        name: 'bedrooms_box',
      },
      {
        key: 'details.bedrooms_double',
        name: 'bedrooms_double',
      },
      {
        key: 'details.bedrooms_single',
        name: 'bedrooms_single',
      },
      {
        key: 'details.bedrooms_twin',
        name: 'bedrooms_twin',
      },
    ],
    bathrooms: [
      {
        key: 'details.bathrooms',
        name: 'bathrooms',
      },
    ],
    livingRooms: [
      {
        key: 'details.reception_rooms',
        name: 'reception_rooms',
      },
    ],
  },
  Jupix: {
    address: [
      {
        key: 'address2',
        name: 'address2',
      },
      {
        key: 'address3',
        name: 'address3',
      },
      {
        key: 'address4',
        name: 'address4',
      },
    ],
    price: [
      {
        key: 'price',
        name: 'price',
      },
    ],
    bedrooms: [
      {
        key: 'propertyBedrooms',
        name: 'propertyBedrooms',
      },
    ],
    bathrooms: [
      {
        key: 'propertyBathrooms',
        name: 'propertyBathrooms',
      },
    ],
    livingRooms: [
      {
        key: 'propertyReceptionRooms',
        name: 'propertyReceptionRooms',
      },
    ],
  },
};
