import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { ButtonGroup } from './CarouselForm.styles';
import logo from '../assets/images/Logo.png';
import { HeaderWrapper } from './Header.styles';
import { ButtonItem } from '../globalStyles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { logout } from '../store/dataSlice';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.data);
  return (
    <HeaderWrapper>
      <ButtonGroup>
        <img src={logo} alt="logo" />
        {isAuth && <ButtonItem onClick={() => dispatch(logout())}>Logout</ButtonItem>}
      </ButtonGroup>
    </HeaderWrapper>
  );
};

export default Header;
