import * as yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export default yup.object().shape({
  // title: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // name: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // middleName: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // lastName: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // suffix: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // companyName: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // customerDisplayName: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // email: yup
  //   .string()
  //   .min(5, 'Username must be at least 5 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // mobilePhoneNumber: yup
  //   .string()
  //   .min(5, 'Username must be at least 5 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // phoneNumber: yup
  //   .string()
  //   .min(5, 'Username must be at least 5 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // fax: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // other: yup
  //   .string()
  //   .min(2, 'Username must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // website: yup
  //   .string()
  //   .min(4, 'Username must be at least 4 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // isSubCustomer: yup
  //   .boolean()
  //   .required('Required'),
  // streetAddress: yup
  //   .string()
  //   .min(5, 'Username must be at least 5 characters long')
  //   .max(150, 'Too long')
  //   .required('Required'),
  // postcode: yup
  //   .string()
  //   .min(3, 'Username must be at least 3 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // city: yup
  //   .string()
  //   .min(3, 'Username must be at least 3 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // county: yup
  //   .string()
  //   .min(2, 'County must be at least 2 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // country: yup
  //   .string()
  //   .min(3, 'Country must be at least 3 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),

  // customerId: yup
  //   .string()
  //   .min(2, 'Username must be at least 4 characters long')
  //   .max(32, 'Too long')
  //   .required('Required'),
  // person: yup
  //   .string()
  //   .min(5, 'Person must be at least 5 characters long')
  //   .max(25, 'Too long')
  //   .required('Required'),
  // phone: yup
  //   .string()
  //   .matches(phoneRegExp, 'Phone number is not valid')
  //   .required('Required'),
  // address: yup
  //   .string()
  //   .min(5, 'Address must be at least 5 characters long')
  //   .max(25, 'Too long')
  //   .required('Required'),
});
