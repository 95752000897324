import React, { useEffect, useState } from 'react';
import { FormTitle, Wrapper } from './CustomerForm.styles';
import { SaveButton } from '../globalStyles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { InnerWrapper, LabelItem, TextItem } from './CustomerPreview.style';
import { ICustomer } from '../models/ICustomer';

interface CustomerPreviewI {
    setCustomerPreview: (value: boolean) => void
    setCustomerForm: (value: boolean) => void
    setEditCustomer: (value: boolean) => void
}

const CustomerPreview: React.FC<CustomerPreviewI> = ({ setCustomerPreview, setCustomerForm, setEditCustomer }: CustomerPreviewI) => {
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<ICustomer>();
  const { selectedCustomer, customers } = useAppSelector((state) => state.data);
  useEffect(() => {
    const customer = customers.find((el) => el.id === selectedCustomer.id);
    setCustomer(customer);
  }, [selectedCustomer.id]);

  const onEdit = async () => {
    setCustomerPreview(false);
    setCustomerForm(true);
    setEditCustomer(true);
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <FormTitle>Current Customer</FormTitle>
        <LabelItem>
          Customer Name
          <TextItem>{customer?.title}</TextItem>
        </LabelItem>

        <LabelItem>
          Contact Person
          <TextItem>{customer?.name }</TextItem>
          <TextItem>{customer?.middleName }</TextItem>
          <TextItem>{customer?.lastName }</TextItem>
        </LabelItem>

        <LabelItem>
          Phone Number
          <TextItem>{customer?.phoneNumber}</TextItem>
        </LabelItem>

        <LabelItem>
          Address
          <TextItem>{customer?.billing.streetAddress}</TextItem>
        </LabelItem>

        <SaveButton onClick={onEdit}>Edit details</SaveButton>
      </InnerWrapper>
    </Wrapper>
  );
};

export default CustomerPreview;
