import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthResponse } from '../models/response/AuthResponse';
import { IUser } from '../models/IUser';

export const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

$api.interceptors.response.use((config) => config, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, { withCredentials: true });
      localStorage.setItem('token', response.data.accessToken);
      return $api.request(originalRequest);
    } catch (e) {
      toast.error('Not Authorized', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  if (error.response.status === 400) {
    toast.error(error.response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  if (error.response.status === 500) {
    toast.error('Server Internal Error', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  throw error;
});

export default $api;
