import styled from '@emotion/styled';

export const LabelItem = styled('label')` 
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #8A8A8A;
  font-weight: 300;
  font-size: 14px;
`;

export const TextItem = styled('p')` 
  color: #646464;
  font-weight: 400;
  font-size: 22px;
  margin: 0;
`;

export const InnerWrapper = styled('div')` 
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: left;
`;

export const InputsRow = styled('div')`
  display: flex;
  gap: 15px;
`;

export const InputsColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
