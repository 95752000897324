import { AxiosResponse } from 'axios';
import $api from '../http';
import { AuthResponse } from '../models/response/AuthResponse';
import { CustomerResponse } from '../models/response/CustomerResponse';

export default class CustomerService {
  static async getAllCustomers(): Promise<AxiosResponse<CustomerResponse>> {
    return $api.get<CustomerResponse>('/customer/get_customers');
  }

  static async createCustomer({
    title,
    name,
    middleName,
    lastName,
    suffix,
    companyName,
    customerDisplayName,
    email,
    mobilePhoneNumber,
    phoneNumber,
    fax,
    other,
    website,
    isSubCustomer,
    billing,
  }: any): Promise<AxiosResponse> {
    return $api.post('/customer/create', {
      title,
      name,
      middleName,
      lastName,
      suffix,
      companyName,
      customerDisplayName,
      email,
      mobilePhoneNumber,
      phoneNumber,
      fax,
      other,
      website,
      isSubCustomer,
      billing,
    });
  }

  static async updateCustomer({
    id,
    title,
    name,
    middleName,
    lastName,
    suffix,
    companyName,
    customerDisplayName,
    email,
    mobilePhoneNumber,
    phoneNumber,
    fax,
    other,
    website,
    isSubCustomer,
    billing,
  }: any): Promise<AxiosResponse> {
    return $api.put('/customer/update', {
      id,
      title,
      name,
      middleName,
      lastName,
      suffix,
      companyName,
      customerDisplayName,
      email,
      mobilePhoneNumber,
      phoneNumber,
      fax,
      other,
      website,
      isSubCustomer,
      billing,
    });
  }
}
