import { useField } from 'formik';
import { InputGroup } from '../../components/CustomerForm.styles';
import { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';
import { CheckboxComponent, CheckboxTitle, CheckboxWrapper } from './CustomRadio.styles';
import CheckboxBorder from '../../assets/icons/RadioButtonBorder';
import CheckboxIcon from '../../assets/icons/RadioButtonIcon';
import Radio from '@mui/material/Radio';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

type ICheckbox = {
    label: string,
    type: string,
    value: string,
    name: string,
} ;

const CustomRadio = ({ ...props }: ICheckbox) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <InputGroup>
      <CheckboxWrapper>
        <FormControlLabel
          {...field}
          {...props}
          control={(
            <Radio
              icon={<CheckboxBorder />}
              checkedIcon={<CheckboxIcon />}
              className={meta.touched && meta.error ? 'input-error' : ''}
            />
)}
        />
        {/* <Radio */}
        {/*  {...field} */}
        {/*  {...props} */}
        {/*  icon={<CheckboxBorder />} */}
        {/*  checkedIcon={<CheckboxIcon />} */}
        {/*  className={meta.touched && meta.error ? 'input-error' : ''} */}
        {/* /> */}
        {/* <CheckboxTitle>{label}</CheckboxTitle> */}
      </CheckboxWrapper>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </InputGroup>
  );
};

export default CustomRadio;
