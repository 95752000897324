import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Global } from '@emotion/react';
import LoginForm from './components/LoginForm';
import { IUser } from './models/IUser';
import MainPage from './components/AdminPageForm';
import { GlobalStyles, LoaderWrapper, MainWrapper } from './globalStyles';
import Header from './components/Header';
import { useAppDispatch, useAppSelector } from './hooks';
import { checkAuth, getOptions, getCustomers } from './store/dataSlice';

const App: FC = () => {
  const { isLoading, isAuth } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress color="primary" />
      </LoaderWrapper>
    );
  }

  if (!isAuth) {
    return (
      <div>
        <MainWrapper>
          <Header />
          <LoginForm />
        </MainWrapper>
        {/* <button onClick={getUsers}>Получить пользователей</button> */}
      </div>
    );
  }

  return (
    <MainWrapper>
      <Global styles={GlobalStyles} />
      <Header />
      <MainPage />
    </MainWrapper>
  );
};

export default App;
