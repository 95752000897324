import * as yup from 'yup';
import { log } from 'util';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

function merge(...schemas: Array<any>) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first,
  );

  return merged;
}

export const basicSchema = yup.object().shape({
  customerId: yup
    .string()
    .required('Required'),
  template: yup
    .string()
    .required('Required'),
  provider: yup
    .string()
    .required('Required'),
  feedLogin: yup
    .string(),
  feedPassword: yup
    .string(),
  feedSecretKey: yup
    .string(),
  firstSlideText: yup
    .string()
    .min(5, 'Address must be at least 5 characters long')
    .max(100, 'Too long')
    .required('Required'),
  photoFile: yup
    .mixed()
  // .test('Required', "Required", value => value?.type && true)
    .test('fileSize', 'File Size is too large', (value) => (value?.size ? value.size <= 500000 : true))
    .test('fileType', 'Unsupported File Format', (value) => (value?.size ? ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/avif', 'image/apng'].includes(value?.type) : true)),

  photoFileBg: yup
    .mixed()
  // .test('Required', "Required", value => value?.type && true)
    .test('fileSize', 'File Size is too large', (value) => (value?.size ? value.size <= 500000 : true))
    .test('fileType', 'Unsupported File Format', (value) => (value?.size ? ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/avif', 'image/apng'].includes(value?.type) : true)),

  isAddressFieldKey: yup
    .string()
    .min(3, 'At least 3 characters long')
    .max(100, 'Too long')
    .required('Required'),
  isPriceFieldKey: yup
    .string()
    .min(3, 'At least 3 characters long')
    .max(100, 'Too long')
    .required('Required'),
  isBedroomsFieldKey: yup
    .string()
    .min(3, 'At least 3 characters long')
    .max(100, 'Too long')
    .required('Required'),
  isBathroomsFieldKey: yup
    .string()
    .min(3, 'At least 3 characters long')
    .max(100, 'Too long')
    .required('Required'),
  isLivingRoomsKey: yup
    .string()
    .min(3, 'At least 3 characters long')
    .max(100, 'Too long')
    .required('Required'),
  isAddressField: yup
    .boolean()
    .required('Required'),
  isPriceField: yup
    .boolean()
    .required('Required'),
  isBedroomsField: yup
    .boolean()
    .required('Required'),
  isBathroomsField: yup
    .boolean()
    .required('Required'),
  isLivingRooms: yup
    .boolean()
    .required('Required'),
});

export const loginPasswordSchema = yup.object().shape({
  feedLogin: yup
    .string()
    .required('Required'),
  feedPassword: yup
    .string()
    .required('Required'),
});

export const secretSchema = yup.object().shape({
  feedSecretKey: yup
    .string()
    .required('Required'),
});
