import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '@mui/material/Button';
import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyles = css`
  body {
    font-family: "Roboto", sans-serif;
  }
  input.input-error,
  select.input-error {
    border-color: #fc8181;
  }
`;

export const MainWrapper = styled('div')`
  position: relative;
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100vh;
`;

export const LoaderWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SaveButton = styled(Button)`
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  background: #ffa85a;
  padding: 12px 50px;
  width: fit-content;
  border-radius: 0;
  text-transform: uppercase;
  &:hover {
    background-color: #e37e23;
    color: #fff;
  }
`;

export const ButtonItem = styled(SaveButton)`
  color: #428dff;
  background: #dbdfe4;
  padding: 12px 25px;
  font-weight: 400;
  font-size: 17px;

  &:hover {
    background-color: #c5d4e7;
    color: #fff;
  }
`;

export const ButtonDelete = styled(ButtonItem)`
  color: #ff0000;
  background: #dbdfe4;
  padding: 12px 25px;
  font-weight: 400;
  font-size: 17px;

  &:hover {
    background-color: #ff0000;
    color: #ffffff;
  }
`;
