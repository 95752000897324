import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { MenuItem } from '@mui/material';

export const Wrapper = styled('div')` 
  padding-right: 35px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const InputWrapper = styled('div')`
  position: relative;
`;

export const SelectItem = styled(Select)`
  fieldset{
    border: none !important;
    outline: none !important;
  }
  .MuiSelect-select {
    padding: 15px;
  }
  min-width: 300px;
  outline: none;
  border-radius: 0;
  border: 1px solid #DBDFE4;
  font-weight: 400;
  font-size: 17px;
  color: #646464;
`;

export const MenuItemElement = styled(MenuItem)`
  font-weight: 400;
  font-size: 17px;
  color: #646464;
`;

export const LabelItem = styled(InputLabel)`
  position: absolute;
  top: -20px;
  font-size: 13px !important;
  font-weight: 300;
  color: #8A8A8A
`;

export const ButtonGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  white-space: nowrap;
`;

export const GroupVertical = styled(ButtonGroup)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FormRow = styled('div')`
 display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
`;

export const FormCol = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 45px;
`;

export const RadioGroupComponent = styled(RadioGroup)`
  position: relative;
`;

export const RadioRow = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const RadioRowLabel = styled('span')`
  position: absolute;
  left: 0;
  top: -20px;
  font-size: 13px!important;
  font-weight: 300;
  color: #8A8A8A;
`;
