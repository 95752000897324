import { useField } from 'formik';
import { InputGroup } from '../../components/CustomerForm.styles';
import { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';
import { CheckboxComponent, CheckboxTitle, CheckboxWrapper } from './CustomCheckbox.styles';
import {
  MuiThemeProvider,
} from '@material-ui/core';
import CheckboxBorder from '../../assets/icons/CheckboxBorder';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';

type ICheckbox = {
    label: string,
    type: string,
} & CheckboxProps;

const CustomCheckbox = ({ label, ...props }: ICheckbox) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <InputGroup>
      <CheckboxWrapper>
        <CheckboxComponent
          {...field}
          {...props}
          icon={<CheckboxBorder />}
          checkedIcon={<CheckboxIcon />}
          className={meta.touched && meta.error ? 'input-error' : ''}
        />
        <CheckboxTitle>{label}</CheckboxTitle>
      </CheckboxWrapper>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </InputGroup>
  );
};

export default CustomCheckbox;
