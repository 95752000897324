import { useField, useFormikContext, Field } from 'formik';
import React, { useRef, useState } from 'react';
import {
  ErrorElement, FormTitle, InputGroup, TextInput,
} from '../../components/CustomerForm.styles';
import { ButtonItem } from '../../globalStyles';
import {
  FileInputElement, FileInputWrapper, SelectedFileName, InputWrapper,
} from './index.styles';

interface Input {
    type: string,
    label: string,
    setFile: (imgData: any) => void,
    errors: any,
    touched: any,
    onBlur?: () => void,
    name: string
}

const CustomFileInput = ({
  label, type, setFile, errors, touched, onBlur, name, ...props
}: Input) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const inputFileRef = useRef(null);
  const onFileChange = (e: any) => {
    if (e.target.files[0]) {
      const file = {
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
      };
      setFile(e.target.files[0]);
      setFieldValue(name, e.target.files[0]);
      setFieldTouched(name, false);
    }
  };
  const onBtnClick = () => {
    // @ts-ignore
    inputFileRef.current.click();
  };

  return (
    <InputWrapper>
      <FileInputWrapper htmlFor="upload-photo">
        <ButtonItem onClick={onBtnClick}>BROWSE</ButtonItem>
        {/* <input id="upload-photo" type="file" name="photoFile" onChange={onFileChange}/> */}
        <FileInputElement
          {...props}
          id="upload-photo"
          ref={inputFileRef}
          type="file"
          onChange={onFileChange}
        />
      </FileInputWrapper>
      {/* // @ts-ignore */}
      {touched[name] && errors[name] && <ErrorElement className="error">{errors[name]}</ErrorElement>}
    </InputWrapper>
  );
};
export default CustomFileInput;
